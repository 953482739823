import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
					<path d="M1370 3851 c-19 -10 -51 -27 -70 -36 -43 -23 -132 -88 -184 -135
-194 -175 -335 -431 -375 -680 -18 -108 -13 -323 8 -425 50 -234 154 -429 316
-592 46 -46 87 -83 93 -83 6 0 18 7 26 16 9 8 16 10 16 4 0 -7 199 -10 708
-10 389 0 719 -2 733 -5 17 -3 31 1 39 10 7 8 16 15 21 15 22 0 258 275 259
302 0 4 17 34 37 65 33 51 38 55 41 34 3 -19 6 -21 13 -10 7 11 9 11 9 -3 0
-23 20 -34 32 -17 8 11 9 10 5 -3 -3 -9 1 -19 9 -22 8 -3 14 -15 14 -26 0 -27
20 -47 51 -52 19 -2 25 -10 27 -35 3 -26 9 -34 30 -39 48 -10 52 -13 52 -34 0
-27 20 -47 51 -52 19 -2 25 -10 27 -35 3 -26 9 -34 30 -39 48 -10 52 -13 52
-34 0 -27 23 -50 49 -50 25 0 30 -8 32 -51 3 -33 4 -34 38 -32 25 2 30 1 17
-5 -15 -7 -11 -10 22 -20 36 -11 46 -10 86 10 25 12 46 26 46 30 0 4 4 8 10 8
16 0 125 87 190 151 149 149 256 338 311 547 29 110 37 355 15 477 -42 242
-157 468 -321 631 -48 48 -95 81 -95 66 0 -5 12 -17 26 -27 14 -9 23 -19 20
-23 -3 -3 -16 4 -29 16 l-23 22 -723 0 c-409 0 -728 -3 -735 -8 -15 -13 -66
-21 -66 -11 0 4 10 9 22 11 13 2 24 11 26 21 4 19 5 20 -83 -37 -17 -10 -61
-57 -100 -103 -38 -46 -76 -90 -82 -98 -7 -7 -13 -17 -13 -21 0 -4 -6 -15 -14
-23 -8 -9 -28 -43 -45 -75 -17 -33 -36 -63 -43 -67 -10 -6 -13 -75 -13 -301 0
-255 2 -299 17 -338 41 -105 192 -136 276 -57 53 49 56 68 60 405 l4 312 109
0 109 0 0 -277 c0 -152 2 -274 5 -271 3 2 16 36 30 74 13 38 60 159 103 269
l78 200 110 3 110 3 68 -173 c38 -95 107 -270 153 -388 47 -118 100 -251 119
-296 19 -45 34 -86 34 -93 0 -8 -34 -11 -122 -9 l-121 3 -28 80 -27 80 -184 3
-183 2 -32 -82 -31 -83 -116 -3 c-70 -2 -116 1 -116 7 0 5 4 21 9 34 9 24 8
24 -61 -12 -70 -36 -71 -36 -197 -36 -118 0 -130 2 -188 29 -73 33 -144 104
-173 171 -35 81 -41 158 -38 469 l3 299 80 -2 c44 -1 91 2 105 8 25 10 23 12
-15 15 -11 0 -25 13 -32 27 -7 15 -26 32 -43 39 -16 7 -30 17 -30 22 0 8 -39
53 -98 111 -12 12 -49 49 -82 82 -33 33 -69 69 -80 80 -12 11 -38 37 -59 58
-21 20 -43 37 -48 37 -6 0 -16 7 -23 15 -10 12 -9 16 4 21 13 5 14 8 1 20 -13
13 -19 13 -47 -2 l-33 -17 19 22 c11 11 25 21 33 21 7 0 13 5 13 10 0 12 2 13
-40 -9z m-205 -1921 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m1505 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z"/>
<path d="M1960 3301 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M2802 2833 l-59 -158 120 -3 c66 -1 122 -1 124 2 3 3 -4 29 -15 58
-64 174 -98 258 -103 258 -4 0 -34 -71 -67 -157z"/>
<path d="M1190 1876 c0 -2 11 -11 25 -20 14 -9 25 -13 25 -8 0 5 -11 14 -25
20 -14 6 -25 10 -25 8z"/>
<path d="M2589 1853 c-13 -15 -12 -15 9 -4 23 12 28 21 13 21 -5 0 -15 -7 -22
-17z"/>
<path d="M3577 1753 c-13 -12 -7 -25 6 -14 8 6 16 8 19 5 3 -3 9 -1 13 5 7 12
-27 15 -38 4z"/>
<path d="M1710 1404 c0 -4 15 -47 33 -95 26 -71 36 -89 52 -89 16 0 26 18 53
89 37 103 37 101 14 101 -12 0 -24 -19 -41 -67 -20 -54 -26 -63 -32 -48 -34
87 -50 115 -63 115 -9 0 -16 -3 -16 -6z"/>
<path d="M1950 1390 c0 -16 7 -20 30 -20 l30 0 0 -75 c0 -59 3 -75 15 -75 11
0 15 17 17 73 3 70 4 72 31 75 19 2 27 9 27 23 0 17 -8 19 -75 19 -68 0 -75
-2 -75 -20z"/>
<path d="M2190 1336 c0 -78 9 -98 51 -111 29 -10 74 12 88 41 6 14 11 52 11
84 0 53 -2 60 -20 60 -18 0 -20 -7 -20 -63 0 -65 -11 -87 -42 -87 -25 0 -38
31 -38 94 0 43 -3 56 -15 56 -12 0 -15 -15 -15 -74z"/>
<path d="M2461 1399 c-20 -8 -23 -14 -18 -43 3 -22 0 -41 -8 -53 -37 -48 -8
-75 86 -79 68 -2 70 -2 54 16 -13 14 -14 23 -6 39 14 26 14 31 -4 31 -8 0 -15
-4 -15 -10 0 -15 -27 -12 -33 3 -3 7 4 19 14 25 26 16 24 47 -3 66 -25 18 -33
19 -67 5z m53 -34 c6 -18 -29 -41 -39 -25 -10 16 2 40 19 40 7 0 16 -7 20 -15z
m-23 -75 c27 -15 25 -42 -3 -38 -15 2 -24 11 -26 26 -4 25 1 27 29 12z"/>
<path d="M2700 1397 c-26 -13 -36 -43 -22 -65 5 -7 27 -19 48 -27 46 -16 59
-45 20 -45 -15 0 -35 5 -46 12 -22 14 -33 8 -25 -15 11 -27 61 -41 95 -27 57
24 50 72 -13 93 -30 10 -42 19 -42 33 0 24 28 30 48 9 19 -19 37 -20 37 -1 0
32 -62 53 -100 33z"/>
<path d="M2890 1350 c0 -70 13 -104 47 -119 35 -16 49 -14 77 12 26 23 29 38
27 120 -1 35 -5 47 -16 47 -12 0 -15 -13 -15 -56 0 -63 -13 -94 -38 -94 -31 0
-42 22 -42 87 0 56 -2 63 -20 63 -18 0 -20 -7 -20 -60z"/>
<path d="M3140 1315 l0 -95 45 0 c52 0 95 17 96 39 2 28 -3 46 -14 53 -9 5 -9
10 1 20 36 36 -7 78 -80 78 l-48 0 0 -95z m95 40 c0 -14 -8 -21 -27 -23 -24
-3 -28 0 -28 23 0 23 4 26 28 23 19 -2 27 -9 27 -23z m12 -71 c7 -19 -13 -34
-44 -34 -18 0 -23 6 -23 25 0 21 5 25 30 25 19 0 33 -6 37 -16z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
